<template>
  <b-card
    class="allotoutbounditem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="allotoutbounditemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库单ID"
            label-for="outbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbound_id"
              size="sm"
              v-model="allotoutbounditem.outbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库单编号"
            label-for="outbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbound_no"
              size="sm"
              v-model="allotoutbounditem.outbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="allotoutbounditem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="allotoutbounditem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label=""
            label-for="stocklock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stocklock_id"
              size="sm"
              v-model="allotoutbounditem.stocklock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="allotoutbounditem.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="batch_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="batch_no"
              size="sm"
              v-model="allotoutbounditem.batch_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="allotoutbounditem.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="有效日期"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="allotoutbounditem.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类ID"
            label-for="category_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_id"
              size="sm"
              v-model="allotoutbounditem.category_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类名称"
            label-for="category_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_name"
              size="sm"
              v-model="allotoutbounditem.category_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌ID"
            label-for="brand_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_id"
              size="sm"
              v-model="allotoutbounditem.brand_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌名称"
            label-for="brand_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_name"
              size="sm"
              v-model="allotoutbounditem.brand_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态 stock_status"
            label-for="warestatus"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warestatus"
              size="sm"
              v-model="allotoutbounditem.warestatus"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="计划调拨数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="allotoutbounditem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际调拨库数量"
            label-for="trueqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="trueqty"
              size="sm"
              v-model="allotoutbounditem.trueqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方仓库是否已接收"
            label-for="is_receive"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_receive"
              size="sm"
              v-model="allotoutbounditem.is_receive"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方实收数量"
            label-for="receiveqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receiveqty"
              size="sm"
              v-model="allotoutbounditem.receiveqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方退货数量(良品)  "
            label-for="returnqty_good"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnqty_good"
              size="sm"
              v-model="allotoutbounditem.returnqty_good"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方退货数量(不良品)  "
            label-for="returnqty_bad"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnqty_bad"
              size="sm"
              v-model="allotoutbounditem.returnqty_bad"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库状态 allot_outbound_status"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="allotoutbounditem.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="启用"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="allotoutbounditem.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="allotoutbounditem.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人ID"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="allotoutbounditem.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="allotoutbounditem.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="allotoutbounditem.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import allotoutbounditemStore from './allotoutbounditemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      allotoutbounditem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('allotoutbounditem')) store.registerModule('allotoutbounditem', allotoutbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutbounditem')) store.unregisterModule('allotoutbounditem')
    })

    const edit = function() {
      store.dispatch('allotoutbounditem/edit', {id: this.id}).then(res => {
        this.allotoutbounditem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('allotoutbounditem/view', {id: this.id}).then(res => {
        this.allotoutbounditem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('allotoutbounditem/save', this.allotoutbounditem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-stockallotoutbounditem-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>